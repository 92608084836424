import React from 'react';
import { MultiDay } from '../components/page-wrappers';
import { Helmet } from 'react-helmet';

// tslint:disable no-default-export
export default () => {
  return (
    <>
      <Helmet title="Baja California Trips">
        <link
          rel="canonical"
          href="https://www.valledeguadalupewinetours.com/baja-california-trips"
        />
      </Helmet>
      <MultiDay />
    </>
  );
};
